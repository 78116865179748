import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [email, setEmail] = useState('');

    const setEmailValue = (newEmail) => {
        setEmail(newEmail);
    };

    return (
        <UserContext.Provider value={{ email, setEmailValue }}>
            {children}
        </UserContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired
};