const ROUTES = {
    HOME: '/',
    //SIGN_IN: '/sign-in',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_CODE: '/verify-code',
    CONSENT: '/consent',
    PRIVACY: '/privacy',
};

export default ROUTES;
