import React, { useState, useEffect } from 'react';
import './ConsentPopUp.scss';
import { Form } from 'react-bootstrap';
import { Button } from '@stillfront/stillfront-component-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../utils/config.js';

function ConsentPopUp() {
    const navigate = useNavigate();
    const [acceptMarketing, setAcceptMarketing] = useState(false);
    const [acceptAge, setAcceptAge] = useState(false);
    const [showAgeError, setShowAgeError] = useState(false);
    const [errorMessageKey, setErrorMessageKey] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        // Trigger the animation upon mounting and updating
        // setActive(false); // Reset to trigger animation
        // const timer = setTimeout(() => setActive(true), 5); // Short delay to trigger transition
        // Send tracking event
        CONFIG.sendTrackingEvent({
            stepId: 'ENTER_CONSENT_DIALOG',
            message: 'User reached the consent step',
        });

        //return () => clearTimeout(timer);
    }, []); // this useEffect runs onMount

    const handleAgeCheckbox = (e) => {
        setAcceptAge(e.target.checked);
        if (e.target.checked) setShowAgeError(false);
    };

    const handleMarketingCheckbox = (e) => setAcceptMarketing(e.target.checked);

    const handleSubmitConsent = async (event) => {
        event.preventDefault();

        // Check if the 'acceptAge' checkbox is checked
        if (!acceptAge) {
            setShowAgeError(true);
            return;
        }
        const consentData = {
            acceptAge: acceptAge,
            acceptMarketing: acceptMarketing,
            acceptTerms: true
        };

        try {
            await CONFIG.apiCall('/api/v1/auth/submit-consent', 'POST', consentData);
            const completeUrl = `${process.env.REACT_APP_API_URL}/api/v1/auth/complete`;
            // Redirect the user to the complete URL
            window.location.href = completeUrl;
            
            // Send tracking event
            CONFIG.sendTrackingEvent({
                stepId: 'SFID_SUCCESS',
                message: 'User successfully signed in.',
            });
        } catch (error) {
            console.error('Error submitting consent:', error);
            const messageKey = error.status 
                ? `errors.${error.status}`
                : 'errors.default';

            setErrorMessageKey(messageKey);
        }
    };

    return (
        <>
            <div className="auth-container">
                <Form onSubmit={handleSubmitConsent}>
                    <div className="consent-container my-4">
                        <Form.Group className="mb-3">
                            <label className="d-flex">
                                <Form.Check
                                    type="checkbox"
                                    aria-label='age'
                                    label=""
                                    checked={acceptAge}
                                    onChange={handleAgeCheckbox}
                                    isInvalid={showAgeError}
                                    className="me-2 align-self-start"
                                />
                                {t('over16Confirmation')}
                            </label>
                        </Form.Group>
                        <Form.Control.Feedback type="invalid">
                            This field is required
                        </Form.Control.Feedback>
                        <Form.Group className="mb-3">
                            <label className="d-flex">
                                <Form.Check
                                    type="checkbox"
                                    aria-label='marketing'
                                    label=""
                                    checked={acceptMarketing}
                                    onChange={handleMarketingCheckbox}
                                    className="me-2 align-self-start"
                                />
                                {t('marketingConsent')}
                            </label>
                        </Form.Group>
                        <p>
                            {t('termsAndPrivacyIntro')}
                            <span className="text-link pb-1" onClick={() => navigate('/privacy')}>{t('terms')}</span> {t('and')} <span className="text-link" onClick={() => navigate('/privacy')}>{t('privacyPolicy')}</span>.
                        </p>
                    </div>
                    {errorMessageKey && <p className="error-message">{t(errorMessageKey)}</p>}
                    <Button type="submit" variant="contained" aria-label='consent-submit' disabled={!acceptAge}>{t('createAccount')}</Button>
                    {/* <Button type="submit" className="submit-btn consent-disabled-btn my-0" disabled={!acceptAge} aria-label='consent-submit'>{t('createAccount')}</Button> */}
                </Form>
            </div>
        </>
    );
}

export default ConsentPopUp;
