import React, { useState, useEffect } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Form, Spinner } from 'react-bootstrap';
import { Button } from '@stillfront/stillfront-component-library';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../utils/config.js';
import './CodeConfirmPopUp.scss';

function CodeConfirmPopUp() {
    const navigate = useNavigate();
    const { email } = useUser();
    const { t } = useTranslation();
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessageKey, setErrorMessageKey] = useState('');
    const [isOtpInvalid, setIsOtpInvalid] = useState(null);
    const [resendCode, setResendCode] = useState(false);

    useEffect(() => {
        // Trigger the animation upon mounting and updating
        // setActive(false); // Reset to trigger animation
        // const timer = setTimeout(() => setActive(true), 5); // Short delay to trigger transition

        // Send tracking event
        CONFIG.sendTrackingEvent({
            stepId: 'ENTER_OTP_DIALOG',
            message: 'User reached the enter otp step',
        });

        //return () => clearTimeout(timer);
    }, []); // this useEffect runs onMount

    const handleOtpChange = (value) => {
        setOtp(value);
        setResendCode(false);
        if (isOtpInvalid !== null) setIsOtpInvalid(null);
    };

    const handleVerifyOtp = async (event, enteredOtp = otp) => {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        setIsLoading(true);
        try {
            const response = await CONFIG.apiCall('/api/v1/auth/verify-otp', 'POST', { otp: enteredOtp });

            if (response.status === 200) {
                console.log('OTP verified successfully, but not proceeding to consent page, since consent already given.');
                window.location.href = `${process.env.REACT_APP_API_URL}/api/v1/auth/complete`;
            } else if (response.status === 201) {
                console.log('OTP verified successfully, creating new user. Asking for consent..');
                navigate('/consent');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsOtpInvalid(true);
            // Send tracking event
            CONFIG.sendTrackingEvent({
                stepId: 'OTP_INPUT_FAILED',
                message: 'OTP verification step failed',
            });
            if (error.status === 401) {
                setErrorMessageKey('expiredOtp');
            } else {
                const statusCode = error.status || 'default';
                setErrorMessageKey(`errors.${statusCode}`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendCode = async () => {
        setIsLoading(true);
        setErrorMessageKey('');
        setOtp('');
        setIsOtpInvalid(false);
        try {
            await CONFIG.apiCall('/api/v1/auth/verify-email', 'POST', { email });
            setResendCode(true);
            console.log('Code sent successfully');
        } catch (error) {
            console.error(error);
            const statusCode = error.status || 'default';
            setErrorMessageKey(`errors.${statusCode}`);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="auth-container">
            <p className="label-text">{t('verificationCodeHasBeenSent')} <strong className='email-text'>{email}</strong></p>
            <p className="label-text">{t('pleaseEnterCodeBelow')}</p>

            <Form onSubmit={(event) => handleVerifyOtp(event)} className="otp-form" aria-label='otp-form'>
                <MuiOtpInput aria-label='otp-input' autoFocus length={6} value={otp} onChange={handleOtpChange} onComplete={(completeOtp) => handleVerifyOtp(null, completeOtp)} className={isOtpInvalid ? 'otp-error' : ''} />
                <div className="d-flex w-100 justify-content-between align-items-center">
                    {isOtpInvalid ? (
                        <div className="error-message">{t(errorMessageKey)}</div>
                    ) : (
                        <div className="spacer"></div>
                    )}
                    <Button variant="text" onClick={handleResendCode} className='p-0'>
                        {t('sendNewCode')}
                    </Button>
                </div>
                {resendCode && <div className="resend-message">{t('newCodeHasBeenSent')}</div>}
            </Form>
            {isLoading && <Spinner className="spinner-position" animation="border" />}
        </div>
    );
}

export default CodeConfirmPopUp;
