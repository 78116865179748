import React from 'react';
import PropTypes from 'prop-types';
import { Image, Container } from 'react-bootstrap';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import './AuthContainer.scss';

function AuthContainer({ headerTitle, bannerSrc, children, showBanner = true }) {
    return (
        // <Modal
        //     aria-labelledby="contained-modal-title-vcenter"
        //     className="modal"
        // >
        //     <Modal.Header closeButton={false} className="modal-header-custom">
        //         {showBackButton && (
        //             <Button variant="link" onClick={onBack} className="back-button">
        //                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" className="bi bi-chevron-left" viewBox="0 0 16 16">
        //                     <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" stroke="white" strokeWidth="0.5"/>
        //                 </svg>
        //             </Button>
        //         )}
        //         {showLanguageSwitcher && (
        //             <LanguageSwitcher />
        //         )}
        //         <Modal.Title id="contained-modal-title-vcenter" className="modal-title-custom">
        //             {headerTitle}
        //         </Modal.Title>
        //         {showCloseButton && (
        //             <Button variant="link" onClick={onHide} className="close-button">
        //                 <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" className="bi bi-x-lg" viewBox="0 0 16 16">
        //                     <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" stroke="white" strokeWidth="0.5"/>
        //                 </svg>
        //             </Button>
        //         )}
        //     </Modal.Header>
        //     <Modal.Body>
        //         {showBanner && bannerSrc && (
        //             <Image src={bannerSrc} fluid className="modal-banner-image" />
        //         )}
        //         <div className="modal-content">
        //             {children}
        //         </div>
        //     </Modal.Body>
        // </Modal>    
        <div className="auth-wrapper">
            <Container className="auth-container">
                <div className='auth-header'>
                    <LanguageSwitcher />
                    <h4 className="auth-title">{headerTitle}</h4>
                </div>
                {showBanner && bannerSrc && (
                    <Image src={bannerSrc} fluid className="auth-banner-image" />)}
                <div className="auth-content">
                    {children}
                </div>
            </Container>
        </div>

    );
}

AuthContainer.propTypes = {
    headerTitle: PropTypes.string,
    bannerSrc: PropTypes.string,
    children: PropTypes.node,
    showLanguageSwitcher: PropTypes.bool
};

export default AuthContainer;
