//import SignInPopUp from '../components/SignInPopUp/SignInPopUp';
import EmailPopUp from '../components/EmailPopUp/EmailPopUp';
import CodeConfirmPopUp from '../components/CodeConfirmPopUp/CodeConfirmPopUp';
import ConsentPopUp from '../components/ConsentPopUp/ConsentPopUp';
//import RewardPopUp from '../components/RewardPopUp/RewardPopUp';
import TermsAndPrivacyPopUp from '../components/TermsAndPrivacyPopUp/TermsAndPrivacyPopUp';

const contentMap = {
    //'/sign-in': SignInPopUp,
    '/verify-email': EmailPopUp,
    '/verify-code': CodeConfirmPopUp,
    '/consent': ConsentPopUp,
    '/privacy': TermsAndPrivacyPopUp,
};

export const getPopupComponent = (pathname) => {
    return contentMap[pathname] || EmailPopUp;
};
