import React, { useState } from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../utils/config';
import './LanguageSwitcher.scss';

function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
    };

    return (
        <>
            <Dropdown className='language-switcher'>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="language-toggle">
                    <Image src={CONFIG.languages.find(lang => lang.code === i18n.language).flag} />
                </Dropdown.Toggle>

                <Dropdown.Menu className='px-3 custom-dropdown-menu'>
                    {CONFIG.languages.map(lang => (
                        <Dropdown.Item
                            key={lang.code}
                            onClick={() => handleLanguageChange(lang.code)}
                            className={selectedLanguage === lang.code ? 'selected' : ''}
                        >
                            <Image src={lang.flag} alt={lang.name} />
                            <span className="native-name">{lang.nativeName}</span>
                            <span className="english-name">({lang.name})</span>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default LanguageSwitcher;
